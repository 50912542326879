import React, { lazy, Suspense, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";
// import { DbContextProvider } from "./DbContext";
const Form = lazy(() => import("./Form"));
const Home = lazy(() => import("./Home"));
const Data = lazy(() => import("./Data"));
const Edit = lazy(() => import("./Edit"));
const Export = lazy(() => import("./Export"));

const App = () => {
	const [screenWidth, setScreenWidth] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    // Set the initial screen width after the component mounts
    setScreenWidth(window.innerWidth);
    setIsMounted(true);

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!isMounted) {
    return null;
  }

  if (screenWidth < 933) {
    return (
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-5xl font-bold text-center mt-12">Seed Buddy</h1>
        <p className="text-center text-3xl mt-6 mb-4 ">
          Your Tool For Managing Seed Data
        </p>
        <div className="text-7xl">🧘</div>
        <p className="text-center text-2xl mt-8 mb-4 mx-8">
          Seed Buddy needs room to breathe. Please use a laptop/desktop or horizontally-positioned tablet.
        </p>
      </div>
    );
  }

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <nav className="w-full  px-8 py-4 text-xl bg-[#84BF86]">
          <ul className="flex flex-row justify-between gap-12 font-bold">
            <li className="hover:scale-125">
              <NavLink to="/">
                {" "}
                <img
                  src="/logo.png"
                  alt="Jill Wagner logo"
                  width="50"
                  style={{ display: "block", margin: "auto" }}
                />
              </NavLink>
            </li>
            <div className="flex gap-12">
              <li className="hover:scale-110">
                <NavLink to="/form">Form</NavLink>
              </li>
              <li className="hover:scale-110">
                <NavLink to="/data">Data</NavLink>
              </li>
							<li className="hover:scale-110">
								<NavLink to="/export">Export</NavLink>
							</li>
            </div>
          </ul>
        </nav>
        {/* <DbContextProvider> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/form" element={<Form />} />
            <Route path="/data" element={<Data />} />
						<Route path="/edit" element={<Edit />} />
						<Route path="/export" element={<Export />} />
          </Routes>
        {/* </DbContextProvider> */}
      </Suspense>
    </Router>
  );
};

export default App;
